import axios from "axios";

const apiClient = axios.create({  //we are creating an apiClient constant (single Axios instance that we can use for our entire app)
    baseURL: 'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3', //base URL for all calls to use
    withCredentials: false,
    headers: {
        Accept: 'application/json',     //for authentication and configuration
        'Content-Type': 'application/json'
    }
});

export default {
    name: "EventService",
    getEvents() {  //exporting a method that is going to return axios apiClient created above which makes get request
        return apiClient.get("/events");  //and we'll pass a stirng "/events" whisch is added onto a baseURL, so we can get all the events from our mock database
    },
    getEvent(id) {
        return apiClient.get("/events/" + id);
    }
}